import React, {  useState } from 'react';


import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import DoubleSlider from "../../SideBarSection/DoubleSlider/DoubleSlider.js";



const FilterBox = ({  onClose , handleSecondFilter}) => {



  const [fields, setFields] = useState([{ selectedElement: '', inputValue: '' }]);
  
 

  const elements = ['Geometry', 'Materials','Frequency', 'Vacuum Wavelength'];

  const [spectrunUnit, setSpectrumUnit] = useState({ 'Frequency': 'THz', 'Vacuum Wavelength': 'nm' });

  const handleSelectChange = (index, event) => {
   
    const newFields = [...fields];
    newFields[index].selectedElement = event.target.value;

    
    setFields(newFields);
  };
  
  const handleInputChange = (index, event) => {
   
    const newFields = [...fields];
    newFields[index].inputValue = event.target.value;
    setFields(newFields);
  };



  const handleAddField = () => {
    setFields([...fields, { selectedElement: '', inputValue: '' }]);
  };
  
  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);


  };


  const handleApplyFilter = () => {

    
    
    handleSecondFilter(fields)
  }

  const handleRangeChange = (index, range) => {


    const newFields = [...fields];
    newFields[index].inputValue = range;
    setFields(newFields);
    
    
  };




return (

  <div className="tmatrix-modal-backdrop" onClick={onClose} style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  }}>
  <div className="tmatrix-modal-content" onClick={e => e.stopPropagation()} style={{
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '600px',
    padding: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
      <button onClick={onClose} className='button-close' style={{
        backgroundColor: '#b9a190',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      }} onMouseOver={e => e.currentTarget.style.backgroundColor = '#c9302c'}
      onMouseOut={e => e.currentTarget.style.backgroundColor = '#b9a190'}>
        Close
      </button>
      <button onClick={handleApplyFilter} className='button-close' style={{
        backgroundColor: '#b9a190',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      }} onMouseOver={e => e.currentTarget.style.backgroundColor = '#c9302c'}
      onMouseOut={e => e.currentTarget.style.backgroundColor = '#b9a190'}>
        Apply Filter
      </button>
      <button onClick={handleAddField} className='button-add' style={{
        backgroundColor: '#5cb85c',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      }} onMouseOver={e => e.currentTarget.style.backgroundColor = '#4cae4c'}
      onMouseOut={e => e.currentTarget.style.backgroundColor = '#5cb85c'}>
        <IoMdAdd />
      </button>
    </div>

    <div style={{ marginBottom: '20px' }}>
    {fields.map((field, index) => (
  <div key={index} style={{ marginBottom: '15px' }}>
    <hr className="separator" style={{
      border: 'none',
      height: '1px',
      backgroundColor: '#ddd',
      marginBottom: '15px',
    }} />

    <select
      id={`element-select-${index}`}
      value={field.selectedElement}  // Access the selectedElement via field
      onChange={(e) => handleSelectChange(index, e)}
      className="input-menu"
      style={{
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        marginBottom: '10px',
        fontSize: '16px',
        outline: 'none',
        boxSizing: 'border-box',
      }}
    >
      <option value="" disabled>Select....</option>
      {elements && <option value="clear">Clear selection</option>}
      {elements.map((element) => (
        <option key={element} value={element}>
          {element}
        </option>
      ))}
    </select>

    {(field.selectedElement === 'Frequency' || field.selectedElement === 'Vacuum Wavelength') ? ( 

              <div>
               <label htmlFor={`element-value-${index}`} style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>{`Select ${field.selectedElement} Range in ${spectrunUnit[field.selectedElement]}:`}</label>
               <DoubleSlider
              min={0}
              max={0}
              onChange={(range) => handleRangeChange(index, range)} 
            />
              </div>

    ) : ( // Access selectedElement via field
    <div style={{ marginBottom: '10px' }}>
      <label htmlFor={`element-value-${index}`} style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>{`Enter ${field.selectedElement} Value:`}</label>
      <input
        type="text"
        id={`element-value-${index}`}
        value={field.inputValue}  // Access inputValue via field
        onChange={(e) => handleInputChange(index, e)}
        className="input-menu"
        style={{
          width: '100%',
          padding: '10px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          fontSize: '16px',
          outline: 'none',
          boxSizing: 'border-box',
        }}
      />
    </div>)}

    <button
      onClick={() => handleRemoveField(index)}
      className='button-close'
      style={{
        backgroundColor: '#b9a190',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      }}
      onMouseOver={e => e.currentTarget.style.backgroundColor = '#c9302c'}
      onMouseOut={e => e.currentTarget.style.backgroundColor = '#b9a190'}
    >
      <RiDeleteBin6Line />
    </button>
  </div>
))}

    </div>

  </div>
</div>

)

}


export default FilterBox;