import axios from "axios";
import Cookies from "js-cookie";

let domain = window.location.hostname;
let api = '/api/'
let url_prod = 'https://' + domain + api
let url_dev = 'http://' + domain +':5000' 

let axiosInstance;


axiosInstance = axios.create({
        baseURL:  url_prod,
        
        https: true,
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
        },
        withCredentials: true,
        credentials: "include",
        xsrfHeaderName: "X-CSRFTOKEN", 
        xsrfCookieName: "csrftoken",
        });



export default axiosInstance;
