import React, { useState, useEffect } from "react";
import './MainApp.css'
import MainSection from '../MainSection/MainSection.js'

import SideBarSection from '../SideBarSection/SideBarSection.js'
import NavBarSection from "../NavBarSection/NavBarSection.js"

import axiosInstance from '../../Axios.js'
import UploadProgressBox from '../MainSection/UploadComponents/UploadProgressBox.js'








export default function MainPage() {



    const [ReceivedGeometryShape, setReceivedGeometryShape] = useState('');
    const [ReceivedMaterial, setReceivedMaterial] = useState('');
    const [GlobalMatrix, setGlobalMatrix] = useState([])
    const [NumPage, setNumPage] = useState(1)
    const [Total, setTotal] = useState(0)
    const [ListMaterial, setListMaterial] = useState([])
    const [Openfilter, setOpenfilter] = useState(false); 

    const [ResultMatrix, setResult_Matrix] = useState([])

    const [ValueMetrics, setValueMetrics] = useState([])
    const [isUploading, setIsUploading] = useState(false);
    const [WaitingMessage, setWaitingMessage] = useState('Searching please wait....');
    const [Actions, setValueActions] = useState('')

  const handleGeometryShape = (data) => {
    setReceivedGeometryShape(data);


  };


  const setActions = (data) =>{

    setValueActions(data)
  }

  const handleMaterial = (data) => {
    
        if(data === ''){

            setReceivedMaterial('');
  
        }else{

            setReceivedMaterial(data);
     
  
            
          
        }


  };


  const handleMetrics = (data) => {

    if(data === ''){

    setValueMetrics(JSON.stringify({}))

  }else{

    setValueMetrics(JSON.stringify(data));


      
    
  }



  }

  const handleNumpage = (number) => {


    setNumPage(number)

  }

  const getAllData = (incrementBy) => {


    const form_data = new FormData();

    form_data.append('NumPage', incrementBy)

    axiosInstance.post('/caosdb_alldata/', form_data,
                     {
                headers:{  
                  "Access-Control-Allow-Headers": "*",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  "Content-Type": "multipart/form-data",}, 

                 
              }
            ).then((response) => {

              setResult_Matrix(response.data['data_parse'])
              setGlobalMatrix(response.data['data_parse'])

              if( response.data['number_elem'] % 8 == 0){

                setTotal(Math.floor( response.data['number_elem']/ 8))

              }else {

                setTotal(Math.floor( response.data['number_elem']/ 8) + 1)

              }
              
                  
            })
            .catch((error) => {
              setResult_Matrix([])
              setGlobalMatrix([])
                              
          
          })




  }


 


   

    const handleSearch = () => {

      let SearchMatrix =[]

      Object.keys(ReceivedGeometryShape).forEach(key => {



        if(ReceivedGeometryShape[key]){

            SearchMatrix.push(key)

        }

      });



     

      const form_data = new FormData();
      
      form_data.append('SearchMatrix', SearchMatrix);
    
      form_data.append('SearchMaterial', ReceivedMaterial);
    
    
    
      form_data.append('SearchMetrics', ValueMetrics);
    
      form_data.append('SearchShapeAction', Actions);
      

      
        axiosInstance.post('/caosdb_search/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {


                if( response.data['data_number'] % 8 == 0){

                  setTotal(Math.floor( response.data['data_number']/ 8))
  
                }else {
  
                  setTotal(Math.floor( response.data['data_number']/ 8) + 1)
  
                }

                setGlobalMatrix(response.data['data'])
                setIsUploading(false)
                    
              })
              .catch((error) => {
              
                setIsUploading(false)
                                
            
            })

       

    }




    const getAllMaterials  = () => {

      let domain = window.location.hostname;
      let api = '/api'
  
      let url_prod = 'https://' + domain + api
    
      let url_dev = 'http://' + domain +':5000' 
  


       // Initialize an EventSource to connect to the streaming endpoint
    const eventSource = new EventSource(`${url_prod}/caosdb_allmaterial`);

    // Handle incoming messages from the server
    eventSource.onmessage = (event) => {
      try {
        // Parse the incoming data (JSON string of material list)
        const newMaterials = JSON.parse(event.data);
        // Update the materials state with the latest data
        setListMaterial(newMaterials);
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    // Handle errors in the event source
    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      eventSource.close(); // Close the connection on error
    };

    // Cleanup function to close the EventSource when the component unmounts
    return () => {
      eventSource.close();
    };
    }


    const handleThirdFilter = (data) => {

      if(data === ''){

        setGlobalMatrix(ResultMatrix)
      }else{



    const form_data = new FormData();
      form_data.append('SearchMatrix', JSON.stringify(data))
  
      
      
        axiosInstance.post('/caosdb_thirdfilter/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {

                setGlobalMatrix(response.data)
                    
              })
              .catch((error) => {console.log(error)
                                
            
            })

      }

    }


  const handleSecondFilter = (field_filter) => {

    setOpenfilter(false)
    setIsUploading(true)


    const form_data = new FormData();
      form_data.append('SearchMatrix', JSON.stringify(field_filter))
  
      
      
        axiosInstance.post('/caosdb_secondfilter/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {


                if( response.data['data_number'] % 8 == 0){

                  setTotal(Math.floor( response.data['data_number']/ 8))
  
                }else {
  
                  setTotal(Math.floor( response.data['data_number']/ 8) + 1)
  
                }

                setGlobalMatrix(response.data['data'])
                setIsUploading(false)
                    
              })
              .catch((error) => {
                setIsUploading(false)
                                
            
            })


  } 


  useEffect(() => {

    if((Actions == 'Include' || Actions == 'Exclude' && (ReceivedGeometryShape || ReceivedMaterial || ValueMetrics)) ){
      
      setIsUploading(true)
      handleSearch()

    }
    
    
  }, [ReceivedGeometryShape, ReceivedMaterial, ValueMetrics, Actions]);

 

    

  useEffect(() => { 


    setNumPage(1)

    getAllData(1)

    getAllMaterials()

    //setisAdmin(true)

  }, []);

   




    return(

        
            
                
<div className="container">
      <NavBarSection />
      <SideBarSection handleGeometryShape ={handleGeometryShape}  setActions = {setActions} handleMaterial = {handleMaterial} ListMaterial ={ListMaterial} handleMetrics = {handleMetrics}  />

      <MainSection data = {GlobalMatrix} handleNumpage = {handleNumpage}  getAllData = {getAllData} Totalelemt = {Total} handleSecondFilter = {handleSecondFilter} handleThirdFilter = {handleThirdFilter}  Openfilter = {Openfilter} setOpenfilter = {setOpenfilter}/>

      { isUploading? <UploadProgressBox WaitingMessage = {WaitingMessage}/> :null}
     
    </div>
             
            
          
    )




}
