import React from 'react';
import '../MainSection.css'
import { IoCafe } from "react-icons/io5";



const UploadingBox = ({WaitingMessage}) => {
    
  
  
    return (
    


        <div style={styles.containerUpload}>
        <div style={styles.uploadBox}>
        <h4 style = {{alignItems: 'center', justifyContent: 'center' , marginLeft : '6%'}}>{WaitingMessage} <IoCafe /></h4>
          
            <div className="spinner"></div>



</div>

</div>
    );
  };
  

  

  const styles = {
    containerUpload: {
      display: 'flex',
      position: 'fixed',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      

      
    },
    uploadBox: {
        display: 'flex',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px', // Corrected to camelCase
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',

    marginLeft : '5%',
    marginTop : '1%',
    width: '30%',
  
    height: '20vh',
    },};

    export default UploadingBox;