import React, { useState, useEffect } from "react";
import './MainApp.css'
import MainSection from '../MainSection/MainSection.js'

import SideBarSection from '../SideBarSection/SideBarSection.js'
import NavBarSection from "../NavBarSection/NavBarSection.js"

import axiosInstance from '../../Axios.js'
import UploadProgressBox from '../MainSection/UploadComponents/UploadProgressBox.js'








export default function MainPage() {



    const [ReceivedGeometryShape, setReceivedGeometryShape] = useState([]);
    const [ReceivedMaterial, setReceivedMaterial] = useState('');
    const [GlobalMatrix, setGlobalMatrix] = useState([])
    const [NumPage, setNumPage] = useState(1)
    const [Total, setTotal] = useState(0)
    const [ListMaterial, setListMaterial] = useState([])
    const [Openfilter, setOpenfilter] = useState(false); 
    const [ClearFilter_geo, setClearFilter_geo] = useState(false)

    const shapeParams = {
            "Sphere": ["radius"],
            "Ellipsoid": ["radiusx", "radiusy", "radiusz"],
            "Superellipsoid": ["radiusx", "radiusy", "radiusz", "n_parm", "e_parm"],
            "Spheroid": ["radiusxy", "radiusz"],
            "Cylinder": ["radius", "height"],
            "Cone": ["radius", "height"],
            "Torus": ["radius_major", "radius_minor"],
            "Cube": ["length"],
            "Rectangular cuboid": ["lengthx", "lengthy", "lengthz"],
            "Helix": ["radius_helix", "radius_wire", "pitch", "number_turns", "termination", "handedness"],
            "Cut sphere": ["radius", "height_z"],
            "Core-shell sphere": ["radius_0", "radius_N"],
        };
    const [ResultMatrix, setResult_Matrix] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const [ValueMetrics, setValueMetrics] = useState([])
    const [isUploading, setIsUploading] = useState(false);
    const [WaitingMessage, setWaitingMessage] = useState('Searching please wait....');
    const [Actions, setValueActions] = useState('')
    const [currentFilter, setCurrentFilter ] = useState({'filter_on': false,
      'value_filter': {}
    })

  const handleGeometryShape = (data_2) => {

    console.log(data_2)

    
      
      setReceivedGeometryShape(data_2);
    


    }
    


 


  const setActions = (data) =>{

    setValueActions(data)
  }

  const handleMaterial = (data) => {
    
        if(data === ''){

            setReceivedMaterial('');
  
        }else{

            setReceivedMaterial(data);
     
  
            
          
        }


  };


  const handleMetrics = (data) => {

    if(data === ''){

    setValueMetrics(JSON.stringify({}))

  }else{

    setValueMetrics(JSON.stringify(data));


      
    
  }



  }

  const handleNumpage = (number) => {

    console.log('handle page : ', number)


    setNumPage(number)

  }

  const getAllData = (incrementBy) => {


    const form_data = new FormData();

    form_data.append('NumPage', incrementBy)

    axiosInstance.post('/caosdb_alldata/', form_data,
                     {
                headers:{  
                  "Access-Control-Allow-Headers": "*",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  "Content-Type": "multipart/form-data",}, 

                 
              }
            ).then((response) => {


              console.log(response)
              setResult_Matrix(response.data['data'])
              setGlobalMatrix(response.data['data'])

              if( response.data['number_elem'] % 8 == 0){

                setTotal(Math.floor( response.data['number_elem']/ 8))

              }else {

                setTotal(Math.floor( response.data['number_elem']/ 8) + 1)

              }
              
                  
            })
            .catch((error) => {
              setResult_Matrix([])
              setGlobalMatrix([])
                              
          
          })




  }



  const handle_NextPrevious = (incrementBy) => {

    setIsLoading(true)


    const form_data = new FormData();

    form_data.append('NumPage', incrementBy)
    form_data.append('FilterOn', JSON.stringify(currentFilter))

    axiosInstance.post('/caosdb_NextPrevious/', form_data,
                     {
                headers:{  
                  "Access-Control-Allow-Headers": "*",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  "Content-Type": "multipart/form-data",}, 

                 
              }
            ).then((response) => {


              console.log(response)
              setResult_Matrix(response.data['data'])
              setGlobalMatrix(response.data['data'])

              if( response.data['data_number'] % 8 == 0){

                setTotal(Math.floor( response.data['data_number']/ 8))

              }else {

                setTotal(Math.floor( response.data['data_number']/ 8) + 1)

              }
              setIsLoading(false)
              
                  
            })
            .catch((error) => {
              setResult_Matrix([])
              setGlobalMatrix([])
                              
          
          })




  }


 


   

    const handleSearch = () => {

      let SearchMatrix =[]
      let searchValue = {'SearchMatrix': SearchMatrix, 
        'SearchMaterial': ReceivedMaterial,
        'SearchMetrics': ValueMetrics,
        'SearchShapeAction': Actions
      }

      console.log(SearchMatrix)
    
      ReceivedGeometryShape.forEach(key => {



        if(key){

          let shape_dict = {}

          shape_dict[key['shape']] = key['parameters']


            SearchMatrix.push( JSON.stringify(shape_dict))

        }

      });



     

      const form_data = new FormData();
      
      form_data.append('SearchMatrix', SearchMatrix);
    
      form_data.append('SearchMaterial', ReceivedMaterial);
    
    
    
      form_data.append('SearchMetrics', ValueMetrics);
    
      form_data.append('SearchShapeAction', Actions);
      

      
        axiosInstance.post('/caosdb_search/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {


                if( response.data['data_number'] % 8 == 0){

                  setTotal(Math.floor( response.data['data_number']/ 8))
  
                }else {
  
                  setTotal(Math.floor( response.data['data_number']/ 8) + 1)
  
                }

                setGlobalMatrix(response.data['data'])
                setIsUploading(false)
                
                setValueActions('')
              
          
                    
              })
              .catch((error) => {
              
                setIsUploading(false)
                                
            
            })

       

    }




  /*  const getAllMaterials  = () => {

      let domain = window.location.hostname;
      let api = '/api'
  
      let url_prod = 'https://' + domain + api
    
      let url_dev = 'http://' + domain +':5000' 
  


       // Initialize an EventSource to connect to the streaming endpoint
    const eventSource = new EventSource(`${url_prod}/caosdb_allmaterial`);

    // Handle incoming messages from the server
    eventSource.onmessage = (event) => {
      try {
        // Parse the incoming data (JSON string of material list)
        const newMaterials = JSON.parse(event.data);
        // Update the materials state with the latest data
        setListMaterial(newMaterials);
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    // Handle errors in the event source
    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      eventSource.close(); // Close the connection on error
    };

    // Cleanup function to close the EventSource when the component unmounts
    return () => {
      eventSource.close();
    };
    } */


    const getAllMaterials  = () => {

     
  
      axiosInstance.get('/caosdb_allmaterial/',
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "Content-Type",}, 
  
                   
                }
              ).then((response) => {
  
  
                console.log(response.data['data'])

                setListMaterial(response.data['data']);
               
                
                    
              })
              .catch((error) => {

                setListMaterial([]);
                
                                
            
            })

    }


    const handleThirdFilter = (data) => {

      if(data === ''){

        setGlobalMatrix(ResultMatrix)
      }else{



    const form_data = new FormData();
      form_data.append('SearchMatrix', JSON.stringify(data))
  
      
      
        axiosInstance.post('/caosdb_thirdfilter/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {
                


                if( response.data_number % 8 == 0){

                  setTotal(Math.floor( response.data_number/ 8))
  
                }else {
  
                  setTotal(Math.floor( response.data_number/ 8) + 1)
  
                }

                setGlobalMatrix(response.data)
                    
              })
              .catch((error) => {console.log(error)
                                
            
            })

      }

    }


  const handleSecondFilter = (field_filter) => {

    setOpenfilter(false)
    setIsUploading(true)

    let searchValue = {'SearchMatrix': JSON.stringify(field_filter)}

    const form_data = new FormData();
      form_data.append('SearchMatrix', JSON.stringify(field_filter))
  
      
      
        axiosInstance.post('/caosdb_secondfilter/', form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Content-Type": "multipart/form-data",}, 

                   
                }
              ).then((response) => {


                if( response.data['data_number'] % 8 == 0){

                  setTotal(Math.floor( response.data['data_number']/ 8))
  
                }else {
  
                  setTotal(Math.floor( response.data['data_number']/ 8) + 1)
  
                }

                setGlobalMatrix(response.data['data'])
                setIsUploading(false)
                setCurrentFilter({'filter_on': true,
                  'value_filter': searchValue
                })
          
                    
              })
              .catch((error) => {
                setIsUploading(false)
                                
            
            })


  } 


  useEffect(() => {

    

    if(((Actions == 'Include' || Actions == 'Exclude') && (ReceivedGeometryShape || ReceivedMaterial || ValueMetrics)) ){


      let SearchMatrix =[]
      let searchValue = {'SearchMatrix': SearchMatrix, 
        'SearchMaterial': ReceivedMaterial,
        'SearchMetrics': ValueMetrics,
        'SearchShapeAction': Actions
      }

    
      ReceivedGeometryShape.forEach(key => {



        if(key){

          let shape_dict = {}

          shape_dict[key['shape']] = key['parameters']


            SearchMatrix.push( JSON.stringify(shape_dict))

        }

      });
    
      setIsUploading(true)
      handleSearch()
      setCurrentFilter({'filter_on': true,
        'value_filter': searchValue
      })


      setValueActions('')

    } 


    
    
  }, [ReceivedGeometryShape, ReceivedMaterial, ValueMetrics, Actions]);

 

    

  useEffect(() => { 


    setNumPage(1)

    getAllData(1)

    getAllMaterials()

    //setisAdmin(true)

  }, []);

   




    return(

        
            
                
<div className="container">
      <NavBarSection />
      <SideBarSection handleGeometryShape ={handleGeometryShape}  setActions = {setActions} handleMaterial = {handleMaterial} ListMaterial ={ListMaterial} ClearFilter_geo={ClearFilter_geo} setClearFilter_geo={setClearFilter_geo} handleMetrics = {handleMetrics} getAllData = {getAllData} handleNumpage = {handleNumpage} shapeParams = {shapeParams}/>

      {isLoading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

      <MainSection data = {GlobalMatrix} NumPage = {NumPage} ClearFilter_geo={ClearFilter_geo}  getAllData = {getAllData} handle_NextPrevious= {handle_NextPrevious} Totalelemt = {Total} handleSecondFilter = {handleSecondFilter} handleThirdFilter = {handleThirdFilter}  Openfilter = {Openfilter} setOpenfilter = {setOpenfilter} ListMaterial = {ListMaterial} shapeParams={shapeParams}/>

      { isUploading? <UploadProgressBox WaitingMessage = {WaitingMessage}/> :null}
     
    </div>
             
            
          
    )




}
