// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Metrics-Elements.css */

.Title-Metrics {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.input-menu {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: #fff;
  transition: border 0.3s ease;
}

.input-menu:focus {
  border-color: #7490c7;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 120, 215, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/Components/SideBarSection/MetricsSection/MetricElement.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,0CAA0C;AAC5C","sourcesContent":["/* Metrics-Elements.css */\n\n.Title-Metrics {\n  font-size: 1rem;\n  font-weight: 600;\n  color: #333;\n  margin-bottom: 15px;\n  text-transform: uppercase;\n}\n\n.input-menu {\n  width: 100%;\n  padding: 8px;\n  margin: 10px 0;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 0.9rem;\n  background-color: #fff;\n  transition: border 0.3s ease;\n}\n\n.input-menu:focus {\n  border-color: #7490c7;\n  outline: none;\n  box-shadow: 0 0 4px rgba(0, 120, 215, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
