import React from 'react';
import '../MainSection.css'

import SideBarTmatrix from './SideBarTmatrix/SideBarTmatrix.js'

import TmatrixFrequencyPlot from './TmatrixMetricPlot/TmatrixFrequencyPlot.js'
import TmatrixInfo from './TmatrixInfo/TmatrixInfo.js'


import TmatrixMetricPlot from './TmatrixMetricPlot/TmatrixMetricPlot.js'
import TmatrixMagnitudePlot from './TmatrixMagnitudePlot/TmatrixMagnitudePlot.js'

const TmatrixContentBox = ({ data, onClose }) => {

  
  //const items = ['T-Matrix', 'Embedding', 'Geometry', 'Material', 'Computation', 'Modes' ]; 

  const rows = []
  rows.push(data)
  

  const [items, setItems] = React.useState([
    { id: 1, name: 'General Information',  content: 'General_Information', selected: false },
    { id: 2, name: 'Interaction cross-section',  content: 'Frequency', selected: false },
    { id: 3, name: 'T-Matrix Elements vs. Frequency', content: 'T-Matrix', selected: false },
    {id: 4, name: 'T-Matrix Magnitude Heatmaps', content: 'Scattered_TMatrix' , selected: false}
   
    
  ]);

  const [selectedContent, setSelectedContent] = React.useState('General_Information');

  const handleItemSelected = (selectedItem) => {
    const newItems = items.map(item =>
      ({ ...item, selected: item.id === selectedItem.id })
    );
    setItems(newItems);
    setSelectedContent(selectedItem.content);
  };


    
   

    const renderContent = () => {
      switch (selectedContent) {
        case 'T-Matrix':
          return <TmatrixMetricPlot data = {data}/>;
        case 'General_Information':
          return <TmatrixInfo data = {data}/>;
        case 'Scattered_TMatrix':
          return <TmatrixMagnitudePlot data = {data}/>;
        case 'Frequency':
          return <TmatrixFrequencyPlot data = {data}/>;
        default:
          return null;
      }
    };

  return (
    <div className="tmatrix-modal-backdrop" onClick={onClose}>
      <div className="tmatrix-modal-content" onClick={e => e.stopPropagation()}>

      <SideBarTmatrix items={items} onItemSelected={handleItemSelected} />

      <div className="table-container">
      {renderContent()}

          </div>

    <div style={{ flex: 1, padding: '10px' }}><button onClick={onClose} className='button-close'>Close</button></div>
    
      </div>
      
    </div>
  );
};

export default TmatrixContentBox;




