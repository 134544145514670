import React, { useEffect, useState } from 'react';
import './MessageValidationBox.css';

import { FaGithub } from "react-icons/fa";

const MessageValidationBox = ({ Validation_Message, data, handleSubmittComment, onClose }) => {
  const [UlpadStatus, setUploadStatus] = useState(true);


 const handleGitHubRedirection = () => {

    let githubLink = 'https://github.com/tfp-photonics/tmatrix_data_format/'

    
    window.open(githubLink, "_blank");
  }

  useEffect(() => {
    // Check validation status in the Validation_Message object
    const hasErrors = Object.keys(Validation_Message).some((key) => {
      const message = Validation_Message[key];
      return message.check_status === false || message.error; // Assuming `check_status` or `error` indicates failure
    });

    // Update the upload status based on the presence of errors
    setUploadStatus(!hasErrors);
  }, [Validation_Message]);

  return (
    <div className="tmatrix-modal-backdrop-v" onClick={onClose}>
      <div className="tmatrix-modal-content-v" onClick={(e) => e.stopPropagation()}>
        {UlpadStatus ? (
          <div className="table-container-comment-v">
            <h4>File Validation!</h4>
            <h5>
              Thank you for your contribution. Please review the validation results below. If
              everything looks correct, you may proceed to submit the file.
            </h5>

            <input
              type="button"
              value="Submit"
              className="btn-upload-v"
              onClick={handleSubmittComment}
            />

            {Object.keys(Validation_Message || {}).map((key) => {
              const validation = Validation_Message[key];
              return (
                <div className="info-container-v" key={key}>
                  <h2>{validation?.file_path || "Unknown File"}</h2>
                  <div>
                    <p>
                      <strong>Check Status:</strong>{" "}
                      {validation?.check_status ? (
                        <span style={{ color: "green" }}>Valid</span>
                      ) : (
                        <span style={{ color: "red" }}>Invalid</span>
                      )}
                    </p>
                  </div>
                  {validation?.warning && (
                    <div>
                      <p>
                        <strong>Warning:</strong> {validation.warning}
                      </p>
                    </div>
                  )}
                  {validation?.check_results?.length > 0 &&
                    validation.check_results.map((result, index) => (
                      <div key={index} className="result-details">
                        <p>
                          <strong>Message:</strong> {result.message}
                        </p>
                      </div>
                    ))}
                  {validation?.error && (
                    <div>
                      <p style={{ color: "red" }}>
                        <strong>Error:</strong> {validation.error}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="table-container-comment-v">
            <h4>Validation Failed!</h4>
            <h5>
            Please review your errors. Consider exploring the example scripts available in this repository to generate T-Matrix.{" "}
              <FaGithub onClick={handleGitHubRedirection} />
            </h5>
            {Object.keys(Validation_Message || {}).map((key) => {
              const validation = Validation_Message[key];
              return (
                <div className="info-container-v" key={key}>
                  <h2>{validation?.file_path || "Unknown File"}</h2>
                  {validation?.error && (
                    <div>
                      <p style={{ color: "red" }}>
                        <strong>Error:</strong> {validation.error}
                      </p>
                    </div>
                  )}
                  {validation?.check_results?.length > 0 &&
                    validation.check_results.map((result, index) => (
                      <div key={index} className="result-details">
                        <p>
                          <strong>Message:</strong> {result.message}
                        </p>
                      </div>
                    ))}
                </div>
              );
            })}
          </div>
        )}

        <div style={{ flex: 1, padding: "10px" }}>
          <button onClick={onClose} className="button-close-v">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
export default MessageValidationBox;
