import React, { useState } from 'react';
import './DoubleSlider.css'; // Ensure to link the CSS file

const DoubleSlider = ({ min, max, onChange }) => {


  const [range, setRange] = useState([min, max]);

  const handleMinChange = (e) => {
    const newMin = e.target.value; // Keep as string while typing

    
    setRange([newMin, range[1]]);
    const numericMin = Number(newMin);
    onChange([numericMin, range[1]]);
    
  
    }
  

  const handleMaxChange = (e) => {
    const newMax = e.target.value; // Keep as string while typing
    
    setRange([range[0], newMax]);
    
    
      const numericMax = Number(newMax);
     
        onChange([range[0], numericMax]);
      
    
  };

  return (
    <div className="double-slider-container">
       <label htmlFor="element-value">{`Select Range:`}</label>
      <div className="input-group">
        <div className="input-wrapper">
          <label htmlFor="min-input" className="input-label">Min Value:</label>
          <input
            type="text"
            id="min-input"
            value={range[0]}
            onChange={handleMinChange}
            className="input-field-slider"
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="max-input" className="input-label">Max Value:</label>
          <input
            type="text"
            id="max-input"
            value={range[1]}
            onChange={handleMaxChange}
            className="input-field-slider"
          />
        </div>
      </div>
    </div>
  );
};

export default DoubleSlider;
