import React, { useEffect, useState , useRef} from "react";

import './MainSection.css'

import TmatrixCard from './TmatrixCard/TmatrixCard.js'

import MessageUploadBox from './UploadComponents/MessageUploadBox.js'

import FilterBox from './FilterBox/FilterBox.js'

import { IoFilterSharp } from "react-icons/io5";

import axiosInstance from '../../Axios.js'

import UploadProgressBox from './UploadComponents/UploadProgressBox.js'

import { TfiReload } from "react-icons/tfi";

import MessageValidationBox from './UploadComponents/MessageValidationBox.js'







export default function MainSection({data, NumPage , ClearFilter_geo ,   getAllData, handle_NextPrevious,  Totalelemt, handleSecondFilter, handleThirdFilter, Openfilter, setOpenfilter, ListMaterial, shapeParams}) {

    const [SearchMatrix, setSearch] = useState("")
    const [ResultMatrix, setResult_Matrix] = useState(data)
    const [files, setFiles] = React.useState([]);
    const [isAdmin, setisAdmin] = React.useState(false);
    const [Upload_Message, setUpload_Message] = useState("")
    const [OpenWindows, setOpenWindows] = useState(false); 
    const [NumPage_local, setNumPage] = useState(1)
    const [ValidationWindows, setValidationWindows] = useState(false)
    const [Validation_Message, setValidation_Message] = useState([])
   

    const [isUploading, setIsUploading] = useState(false);
    const [WaitingMessage, setWaitingMessage] = useState('');
 
    const fileInputRef = useRef(null);
  
   


  const clearFileInput = () => {
    // Check if the ref and current are not null
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

    const handleFiles = (event) => {
      
      //setFiles(event.target.files);
      if (event.target.files.length > 0) {
        setFiles(Array.from(event.target.files));
      } else {
        setFiles([]);
      }
      
     // handleUpload()
    };
    

    
    const handleUpload = (event) => {

      setWaitingMessage('Uploading your file, please wait....')

      setValidationWindows(false)
      
      if(files.length > 10){

        setUpload_Message("You have uploaded more than 10 files. Please reduce the number of files to 10 or fewer to proceed.")
        
        setOpenWindows(true)

      }else{
      setIsUploading(true)

      const form_data = new FormData();

      for (let i = 0; i < files.length; i++) {
        form_data.append(`files[${i}]`, files[i]);
    }
      form_data.append('username', localStorage.getItem('user_id'))

    axiosInstance.post('/caosdb_insert/',form_data,
                       {
                  headers:{  
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "content-type": "multipart/form-data",}, 
                      
                }
              ).then((response) => {
                    
                
                setFiles([])
                clearFileInput();
                getAllData(1)

                setUpload_Message('Upload OK')
                setIsUploading(false)
                setOpenWindows(true)
              })
              .catch((error) => {



                let message_error = error.response['data']
                console.log(error.response['data'])
                
                
                setFiles([])
                clearFileInput();
                getAllData(1)
                setUpload_Message(message_error['Error'])
                setIsUploading(false)
                setOpenWindows(true)
                                
            
            })
          }           
                                
                }


  const handleValidation = (event) => {

    setWaitingMessage("Validating your file, please wait...")
      
                  if(files.length > 10){
            
                    setUpload_Message("You have uploaded more than 10 files. Please reduce the number of files to 10 or fewer to proceed.")
                    
                    setOpenWindows(true)
            
                  }else{
                  setIsUploading(true)
            
                  const form_data = new FormData();
            
                  for (let i = 0; i < files.length; i++) {
                    form_data.append(`files[${i}]`, files[i]);
                }
                  form_data.append('username', localStorage.getItem('user_id'))
            
                axiosInstance.post('/caosdb_ValidateFile/',form_data,
                                   {
                              headers:{  
                                "Access-Control-Allow-Headers": "*",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Credentials": "true",
                                "content-type": "multipart/form-data",}, 
                                  
                            }
                          ).then((response) => {
                                
                            
                            //setFiles([])
                            //clearFileInput();
                            //getAllData(1)

                            console.log(response.data)
            
                            setUpload_Message('Validation Done!')
                            setValidation_Message(response.data['result'])
                            setIsUploading(false)
                            setValidationWindows(true)
                          })
                          .catch((error) => {
                            
                            
                            //setFiles([])
                            //clearFileInput();
                            //getAllData(1)
                            setUpload_Message('Validation Not Done!')
                            setIsUploading(false)
                            setValidationWindows(true)
                                            
                        
                        })
                      }           
                                            
                            }

    

    
    const handleInputChange = (event) => {

       
      if(event.target.value === ''){

        setResult_Matrix(data)
      }else{

        
        setSearch(event.target.value)
        handleThirdFilter(event.target.value)
        
      }
        
    }
 

    const handleClear = () => {

      
      axiosInstance.post('/caosdb_ClearModel/', 
                     {
                headers:{  
                  "Access-Control-Allow-Headers": "*",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  "Content-Type": "application/json",}, 

                 
              }
            ).then((response) => {

              getAllData(1)
                  
            })
            .catch((error) => {console.log(error)
                              
          
          })

     

  }

  const handleMigrate = () => {

      
    axiosInstance.post('/caosdb_migration/', 
                   {
              headers:{  
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Content-Type": "application/json",}, 

               
            }
          ).then((response) => {

            getAllData(1)
                
          })
          .catch((error) => {

            getAllData(1)
                            
        
        })

   

}

const handleNextPage = (event) => {

  setNumPage(NumPage_local + 1)



  handle_NextPrevious(NumPage_local + 1)

}




const handlePreviousPage = (event) => {


  if (NumPage_local - 1 == 1 || NumPage_local - 1 == 0){

    setNumPage(1)

    handle_NextPrevious(1)

  }else{

    setNumPage(NumPage_local - 1)

    handle_NextPrevious(NumPage_local - 1)

  }


 

  

}

 


    const handleDownLoadGlobal = (event) => {

  

    let list_link = []

    for (const el of data){
      list_link.push(el.source_file)

    }
  
    const form_data = new FormData();

    form_data.append('Download', list_link)

    axiosInstance.post('/caosdb_MassiveDownload/', form_data, {
      headers:{  
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "multipart/form-data",}, 
      responseType: 'blob', // Tells Axios to expect a binary response
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.zip'); // Ensure this has a file extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    }).catch((error) => {
      console.error(error);
    });

  
                                
                                
                }


             //   React.useEffect(() =>{

               //   if(localStorage.getItem('user_id') == 'kaoutarcpge'){

                 //   setisAdmin(true)

                 // }//

                
                  

               // }, [])
  
               useEffect(() => {
                       
               
                       if(ClearFilter_geo === true){
               
                           
                        setNumPage(NumPage)
                          
                       }
                     
                   
                   }, [ClearFilter_geo]);
               


    return(




      <div className="main">
      <div className="horizontal-bar">
       
      

       <div style={{ marginTop: '0.5%' , cursor: 'pointer', borderRadius: '5px'}}  onClick ={() => {setOpenfilter(true)}}><IoFilterSharp />  Filter</div>
       
 
      
        
        <input
 
         type="text"
         id="search"
          placeholder="Type Description or filename....."
          onChange={handleInputChange}
          className="input-field"
          //disabled
    />
 
       <input
                   type="button"
                   value="Upload"
                   className="btn-upload"
                   onClick={handleValidation}
       /> 
       <input
               id="contained-button-file"
               multiple
               type="file"
               className="select-file"
               onChange={handleFiles}
               ref={fileInputRef}
             />

 
       <input
                   type="button"
                   value="Export"
                   className="btn-upload"
                   onClick={handleDownLoadGlobal}
       /> 

<div style={{ marginTop: '0.5%' , cursor: 'pointer', borderRadius: '5px', marginLeft : '2%'}}  onClick ={() => { getAllData(1)}}><TfiReload /> Reload</div>
 
   { isAdmin ? <div><input
                   type="button"
                   value="ClearDB"
                   className="btn-upload"
                   onClick={handleClear}
       /> 
 </div>: null}
 { isAdmin ? <div>
 <input
                   type="button"
                   value="Migrate"
                   className="btn-upload"
                   onClick={handleMigrate}
       /> </div>: null}
 
      </div>

      <TmatrixCard data = {data}/>  


      <div className="pagination-container">
      <button className="pagination-button" onClick={handlePreviousPage} disabled={( NumPage_local - 1 == 0)}>
        &laquo; Previous
      </button>
      <span className="pagination-info"> Page {NumPage_local} of {Totalelemt} </span>
      <button className="pagination-button" onClick={handleNextPage} disabled={(NumPage_local == Totalelemt)}>
        Next &raquo;
      </button>
      
    </div>
    
     
      {OpenWindows ? <MessageUploadBox data = {Upload_Message} onClose={() => {setOpenWindows(false)}} /> :null}
      {ValidationWindows ? <MessageValidationBox Validation_Message = {Validation_Message} data = {Upload_Message} handleSubmittComment = {handleUpload} onClose={() => {setValidationWindows(false)}}/>:null}
      { isUploading? <UploadProgressBox WaitingMessage = {WaitingMessage}/> :null}

      {Openfilter ? <FilterBox   ListMaterial = {ListMaterial} shapeParams = {shapeParams} handleSecondFilter = {handleSecondFilter} onClose={() => {setOpenfilter(false)}}/> :null}
   
 
   
 
    </div>


      

  );


  

}
