import React, { useEffect, useState } from 'react';

import './SideBarSection.css'


import GeometryShape from './GeometrySection/GeometryShape.js'

import MetricsElemts from './MetricsSection/MetricElemt.js'

import MaterialElemts from './MaterialSection/MaterialElement.js'



export default function SideBarSection({handleGeometryShape, setActions,   handleMaterial, ListMaterial, handleMetrics, getAllData, handleNumpage}) {


    const [ShowAction, setValueShowAction] =  useState(false)

    const [DisplayButton, setDisplayButton] = useState(false)
    





    const setShowAction = (data) => {

        setValueShowAction(data)
        setDisplayButton(true)

    }



    const handleClearFilter = () => {



        setDisplayButton(false); 
        setValueShowAction(false)
        getAllData(1)
        handleNumpage(1)

    } 

    

    return(

        

<section className="SideBar">

    <div>


    <GeometryShape sendGeometryShape ={handleGeometryShape} setShowAction = {setShowAction}/>

    <MetricsElemts  handleMetrics = {handleMetrics}  setShowAction ={setShowAction}/>

    <MaterialElemts  handleMaterial = {handleMaterial} ListMaterial = {ListMaterial} setShowAction ={setShowAction}/>

    {(ShowAction && DisplayButton) ? <div> <input
                   type="button"
                   value="Refine"
                   class="btnf include"
                   onClick={()=> {setActions('Include')}}
       /> 

    <input
                   type="button"
                   value="Exclude"
                   class="btnf exclude"
                   onClick={()=> {setActions('Exclude')}}
       /> </div> : (!ShowAction && DisplayButton) ? <div> 

                    <input
                        type="button"
                        value="Clear Filter"
                        class="btnf exclude"
                        onClick={handleClearFilter}
                    /> </div> :null}

    
    </div>

</section>
    


        
    )




}