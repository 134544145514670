import React from "react";
import SwaggerUI from "swagger-ui-react"; 
import "swagger-ui-react/swagger-ui.css"; 
import "./ApiComponent.css";





export default function ApiComponent() {
  return (
    <div className="api-container">
      <h1>T-matrix APIs</h1>
      <SwaggerUI url="https://tmatrix.scc.kit.edu/swaggerfile/swagger.json" />
    </div>
  );
}
