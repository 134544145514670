import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import './UserGuide.css'

function UserGuide() {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {


    let url_file = "https://tmatrix.scc.kit.edu/documentationmarkdown/documentation.md"
    
    fetch(url_file)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <div className="documentation-container">

      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markdown}</ReactMarkdown>
    </div>
  );
}

export default UserGuide;
