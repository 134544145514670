

import React, { useState } from 'react';
import '../../MainSection.css'
import Box from '@mui/material/Box';




const TmatrixFrequencyPlot = ({ data }) => {


    
    
    let domain = window.location.hostname;
    let api = '/api'

    let url_prod = 'https://' + domain + api
  
    let url_dev = 'http://' + domain +':5000' 

 




return(



    <div>

<h4>Frequency / Angular frequency </h4>


<Box sx={{ height:'800px', width: '100%', marginBottom :'20%' }}>

<iframe 
        src={`${url_prod}/create_plot_frequency/${data.tmatrix_uuid}`} 
        width="700px" 
        height="800px"
        style={{ border: 'none' }}
        title="Plotly Plot"
      />
</Box>


     
    




    </div>
)


};

export default TmatrixFrequencyPlot ;
