import React, { useEffect, useState } from 'react';
import './GeometryShape.css';

export default function GeometryShape({ sendGeometryShape, setShowAction , ClearFilter, shapeParams, setClearFilter_geo, handleNumpage}) {
    const [boxParams, setBoxParams] = useState(false);
    const [selectedShape, setSelectedShape] = useState(null);
    const [paramValues, setParamValues] = useState({});
    const [submittedData, setSubmittedData] = useState([]);
    
   

    const [checkedItems, setCheckedItems] = useState(
        Object.keys(shapeParams).reduce((acc, shape) => ({ ...acc, [shape]: false }), {})
    );

    const handleCheckboxChange = (event) => {

        const { name, checked } = event.target;

        setCheckedItems(prevState => ({
            ...prevState,
            [name]: checked,
        }));

        if(!checked){
            setSubmittedData(prevData => prevData.filter(item => item.shape !== name));
        }


        

        console.log(checked)
        console.log(checkedItems)
        console.log(selectedShape)

        setShowAction(checked);
        setSelectedShape(checked ? name : null);
        
        setBoxParams(checked);

       
    };

    const handleParamChange = (event) => {
      const { name, value } = event.target;
      setParamValues(prevState => ({
          ...prevState,
          [name]: value,
      }));
  };


  const handleSubmit = () => {
    const newData = { shape: selectedShape, parameters: { ...paramValues } };
    setSubmittedData(prevData => [...prevData, newData]);
   
    setBoxParams(false);
    setSelectedShape(null);
    setParamValues({});
};

    useEffect(() => {

        console.log("Submitted Data:", submittedData);

            sendGeometryShape( submittedData);
      
    }, [submittedData]);

    useEffect(() => {
        

        if(ClearFilter === true){

            setSubmittedData([])
            handleNumpage(1)
            setClearFilter_geo(false)
        }
      
    
    }, [ClearFilter]);



    const closeModal = () => {
      setBoxParams(false);
      setSelectedShape(null);
      setParamValues({});
  };
    return (
        <div>
            <h2 className='Title-Geometry'>Geometry Shape</h2>
            <hr className="separator" />

            <div className='Select-item'>
                {Object.keys(checkedItems).map((item) => (
                    <div key={item} className="checkbox-item">
                        <label>
                            <input
                                type="checkbox"
                                name={item}
                                checked={checkedItems[item] || false}
                                onChange={handleCheckboxChange}
                            />
                            {item}
                        </label>
                    </div>
                ))}
            </div>

            {boxParams && selectedShape && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{selectedShape} Parameters</h3>
                        <ul>
                            {shapeParams[selectedShape].map((param, index) => (
                                <li key={index}>
                                <label>{param}: </label>
                                <input 
                                    type="text" 
                                    name={param} 
                                    value={paramValues[param] || ''} 
                                    onChange={handleParamChange} 
                                />
                            </li>
                            ))}
                        </ul>
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            )}
        </div>
    );
}
