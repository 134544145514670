

import React, { useState } from 'react';
import '../../MainSection.css'




const TmatrixInfo = ({ data }) => {

    


    

    const getKeywords = (currentElement) => {
    
        const keywordString = currentElement?.tmatrix_Keyword;
        //console.log(typeof(keywordString))
    
        //console.log(keywordString)
    
        if (!keywordString) {
           
            return "No keywords"; 
        }
    
        try {
    
          if (typeof(keywordString) === 'string') {
               return keywordString;
    
          }else{
            
          const sanitizedString = keywordString.replace(/'/g, '"');
          const keywordsArray = JSON.parse(sanitizedString);
    
            
            if (Array.isArray(keywordsArray)) {
                return keywordsArray.join(', ');
            } else {
                
                return "Invalid keyword format"; 
            }}
        } catch (error) {
            console.error("Parsing error:", error);
            return "Error parsing keywords"; 
        }
    };

   

    const info = {
        Shape: data.geometry_shape,
        Material: data.material_name,
        Description: data.tmatrix_description,
        
        Keyword: getKeywords(data)
    };








return(


<div className="info-container">
            <h2>{info.title}</h2>
            <p><strong>Shape:</strong> {info.Shape}</p>
            <p><strong>Material:</strong> {info.Material}</p>
            <p><strong>Description:</strong> {info.Description}</p>
            <p><strong>Keyword:</strong> {info.Keyword}</p>
        </div>
)


};

export default TmatrixInfo ;