
import React, { useState } from 'react';

import './MaterialElement.css'



export default function MaterialElemts ({handleMaterial, ListMaterial, setShowAction} ) {

  const [Materials, setMaterials] = useState('')



  const handleSelectChange = (data) => {
    const value = data;
    if (value === 'clear') {
      setMaterials(''); // Reset to the default placeholder value
      handleMaterial('')
      setShowAction(false)
    } else {
      setMaterials(value);
      handleMaterial(value)
      setShowAction(true)
    }
  };


  
  



    


    return (

 

    <div>
 <h2 className='Title-Material'> Material</h2>

<hr className="separator" />







      <div >
      <select id="dropdown" value={Materials}  onChange={e => handleSelectChange(e.target.value)} className="input-menu">
        <option value="" disabled>Select Material</option>
        {Materials && <option value="clear">Clear selection</option>}
        {ListMaterial.length > 0 ? (
          ListMaterial.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))
        ) : (
        <option disabled>No options available</option>
        )}

      

      </select>
    </div>
     
      </div>
    
      
    

      




    )

    


};