import React, { useEffect, useState } from 'react';




const MessageUploadBox = ({ data, onClose }) => {




  const [UlpadStatus, setUploadStatus] = useState(true);

  const [CommentUpload, setCommentUpload] = useState('');


  const handleCommentUpload =(event) =>{



    setCommentUpload(event.target.value)

  }


  const handleSubmittComment =() =>{



    onClose()

  }



useEffect(() => { 

    if(data == 'Upload OK'){
        setUploadStatus(true)
    }else{
        setUploadStatus(false)
    }

 

}, []);







return (

    <div className="tmatrix-modal-backdrop" onClick={onClose}>
      <div className="tmatrix-modal-content" onClick={e => e.stopPropagation()}>


     
    

    {UlpadStatus?  <div className="table-container-comment">
      <h4>Thank you for uploading your Tmatrix! </h4>

<h5>We appreciate your contribution. If you have any additional comments or feedback, please share them with us below.</h5>

<input

        type="text"
        id="Comment"
         placeholder="Type your comment..."
         onChange={handleCommentUpload}
         className="comment-field"
       />

<input
                  type="button"
                  value="Submit"
                  className="btn-upload"
                  onClick={handleSubmittComment}
      /> 
</div> : <div className="table-container-comment">
      <h4>Upload Failed! </h4>

<h5>{data}</h5>
</div> }
        

          

    <div style={{ flex: 1, padding: '10px' }}><button onClick={onClose} className='button-close'>Close</button></div>
    
      </div>
      
    </div>
)

}


export default MessageUploadBox;