import React, { useEffect, useState } from 'react';

import './SideBarSection.css'


import GeometryShape from './GeometrySection/GeometryShape.js'

import MetricsElemts from './MetricsSection/MetricElemt.js'

import MaterialElemts from './MaterialSection/MaterialElement.js'



export default function SideBarSection({handleGeometryShape, setActions,   handleMaterial, ListMaterial, handleMetrics}) {


    const [ShowAction, setValueShowAction] =  useState(false)
    





    const setShowAction = (data) => {

        setValueShowAction(data)

    }

    

    return(

        

<section className="SideBar">

    <div>


    <GeometryShape sendGeometryShape ={handleGeometryShape} setShowAction = {setShowAction}/>

    <MetricsElemts  handleMetrics = {handleMetrics}  setShowAction ={setShowAction}/>

    <MaterialElemts  handleMaterial = {handleMaterial} ListMaterial = {ListMaterial} setShowAction ={setShowAction}/>

    {ShowAction? <div> <input
                   type="button"
                   value="Refine"
                   class="btnf include"
                   onClick={()=> {setActions('Include')}}
       /> 

    <input
                   type="button"
                   value="Exclus"
                   class="btnf exclude"
                   onClick={()=> {setActions('Exclude')}}
       /> </div> :null}

    
    </div>

</section>
    


        
    )




}