import React, { useEffect, useState } from 'react';
import './GeometryShape.css';

export default function GeometryShape({ sendGeometryShape, setShowAction }) {
    const [boxParams, setBoxParams] = useState(false);
    const [selectedShape, setSelectedShape] = useState(null);
    const [paramValues, setParamValues] = useState({});
    const [submittedData, setSubmittedData] = useState([]);
    
    const shapeParams = {
        "Sphere": ["radius"],
        "Ellipsoid": ["radiusx", "radiusy", "radiusz"],
        "Superellipsoid": ["radiusx", "radiusy", "radiusz", "n_parm", "e_parm"],
        "Spheroid": ["radiusxy", "radiusz"],
        "Cylinder": ["radius", "height"],
        "Cone": ["radius", "height"],
        "Torus": ["radius_major", "radius_minor"],
        "Cube": ["length"],
        "Rectangular cuboid": ["lengthx", "lengthy", "lengthz"],
        "Helix": ["radius_helix", "radius_wire", "pitch", "number_turns", "termination", "handedness"],
        "Cut sphere": ["radius", "height_z"],
        "Core-shell sphere": ["radius_0", "radius_N"],
    };

    const [checkedItems, setCheckedItems] = useState(
        Object.keys(shapeParams).reduce((acc, shape) => ({ ...acc, [shape]: false }), {})
    );

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems(prevState => ({
            ...prevState,
            [name]: checked,
        }));

        setShowAction(checked);
        setSelectedShape(checked ? name : null);
        setBoxParams(checked);
    };
    const handleParamChange = (event) => {
      const { name, value } = event.target;
      setParamValues(prevState => ({
          ...prevState,
          [name]: value,
      }));
  };

  const handleSubmit = () => {
    const newData = { shape: selectedShape, parameters: { ...paramValues } };
    setSubmittedData(prevData => [...prevData, newData]);
    console.log("Submitted Data:", newData);
    setBoxParams(false);
    setSelectedShape(null);
};

    useEffect(() => {
        sendGeometryShape(checkedItems);
    }, [checkedItems]);

    const closeModal = () => {
      setBoxParams(false);
      setSelectedShape(null);
      setParamValues({});
  };
    return (
        <div>
            <h2 className='Title-Geometry'>Geometry Shape</h2>
            <hr className="separator" />

            <div className='Select-item'>
                {Object.keys(checkedItems).map((item) => (
                    <div key={item} className="checkbox-item">
                        <label>
                            <input
                                type="checkbox"
                                name={item}
                                checked={checkedItems[item] || false}
                                onChange={handleCheckboxChange}
                            />
                            {item}
                        </label>
                    </div>
                ))}
            </div>

            {boxParams && selectedShape && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{selectedShape} Parameters</h3>
                        <ul>
                            {shapeParams[selectedShape].map((param, index) => (
                                <li key={index}>
                                <label>{param}: </label>
                                <input 
                                    type="text" 
                                    name={param} 
                                    value={paramValues[param] || ''} 
                                    onChange={handleParamChange} 
                                />
                            </li>
                            ))}
                        </ul>
                        <button onClick={handleSubmit}>Submit</button>
                        <button onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}
