import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {


    let url_file = "https://tmatrix.scc.kit.edu/privacypolicymarkdown/privacypolicy.md"
    
    fetch(url_file)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <div className="documentation-container">

      <ReactMarkdown>{markdown}</ReactMarkdown>
    </div>
  );
}

export default PrivacyPolicy;
