import React, { useState } from 'react';
import '../MainSection.css'
import axiosInstance from '../../../Axios.js'
import { ImDownload } from "react-icons/im";
import { ImEye } from "react-icons/im";
import image_nano from  '../../../img/image_3.jpg';

import TmatrixContent from '../TmatrixContentBox/TmatrixContentBox.js'


const TmatrixCard = ({ data }) => {



  const [SelectedCard, setSelectedCard] = useState();
  const [SelectedElement, setSelectedElement] = useState([]);

  const [OpenWindows, setOpenWindows] = useState(false);

  


  const currentElements = {};
  const elementsPerPage = 8;

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(Object.keys(data).length / elementsPerPage);

 
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
 
  const keys = Object.keys(data);
  

  const firstFiveKeys = keys.slice(indexOfFirstElement, indexOfLastElement);

  
  firstFiveKeys.forEach(key => {
    currentElements[key] = data[key];
  });

  

 


  const handleDownload = (index) => {

  

    setSelectedCard(index)

    //console.log(data[index].source_file)

   
   
  
    const form_data = new FormData();
    form_data.append('hdfile', data[index].source_file);
  
    axiosInstance.post('/caosdb_download/', form_data, {
      headers:{  
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "multipart/form-data",}, 
      responseType: 'blob', 
    }).then((response) => {

      console.log(response.data)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let file_name = data[index].source_file
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); 
    }).catch((error) => {
      console.error(error);
    });
      
    
  };

  const handlehDisplayCard = (index) =>{

     setSelectedElement(data[index])

    

    

   
    setOpenWindows(true)

  }

  const getKeywords = (currentElement, key) => {
    
    const keywordString = currentElement[key]?.tmatrix_Keyword;
    //console.log(typeof(keywordString))

    //console.log(keywordString)

    if (!keywordString) {
       
        return "No keywords"; 
    }

    try {

      if (typeof(keywordString) === 'string') {
           return keywordString;

      }else{
        
      const sanitizedString = keywordString.replace(/'/g, '"');
      const keywordsArray = JSON.parse(sanitizedString);

        
        if (Array.isArray(keywordsArray)) {
            return keywordsArray.join(', ');
        } else {
            
            return "Invalid keyword format"; 
        }}
    } catch (error) {
        console.error("Parsing error:", error);
        return "Error parsing keywords"; 
    }
};

  return (
    <div>
      
    <div className="Matrix-element">

    



<div className="table-container">


<div className="cards-container">
        {Object.keys(currentElements).map((key) => (
          <div className="card-container">
            <div className="card-header" style={{ /*backgroundColor: colors[Math.floor(Math.random() * colors.length)]*/  backgroundImage: `url(${image_nano}`,  backgroundSize: 'cover' }}></div>
            <div className="card-content">
              <h3>{currentElements[key]['geometry_shape']}  </h3>
              {<p>Material : {currentElements[key]['material_name']}</p>}
              <p>Description : {currentElements[key]['tmatrix_description']}</p>
              <p>Keywords : {getKeywords(currentElements, key)}</p>
              
              <p className='p-style'><ImEye className="Icon-show" onClick={() => {handlehDisplayCard(key)}}/> <ImDownload className="Icon-download" onClick={() => {handleDownload(key)}}/></p>
              
              
              
            </div>
          </div>
        ))}
      </div>




      { OpenWindows ? <TmatrixContent data={SelectedElement} onClose={() => {setOpenWindows(false)}} />: null}
          
      
      </div>

      

    </div>

   
    </div>
  );
};

export default TmatrixCard ;
