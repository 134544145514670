import React from "react";
import './NotFoundPage.css'

export default function NotFoundPage() {
  return(
    <div className="Error">         
      <h4>Opps!</h4>
      <h3>We can not find the page you are looking for.</h3>
    </div>
  )     
}

