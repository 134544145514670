import React from "react";
import SwaggerUI from "swagger-ui-react"; 
import "swagger-ui-react/swagger-ui.css"; 
import "./ApiComponent.css";
import { FaFastBackward } from "react-icons/fa";


const HideServersPlugin = () => ({
  wrapComponents: {
    Servers: () => () => null,

    Parameters: () => () => null 
  }
});



export default function ApiComponent() {

  const handleBackOption = () => {

    window.location.href = "/Home";

  }

  return (

    <div className="api-container">
      <FaFastBackward onClick={handleBackOption}/>
      
      <h1>T-matrix APIs</h1>
      <SwaggerUI 
      url="https://tmatrix.scc.kit.edu/swaggerfile/swagger.json" 
      filter={true}
      plugins={[HideServersPlugin]}
      />
    </div>

  );
}
