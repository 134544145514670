// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .container {
    display: grid;
    grid-template-rows: 60px 1fr;  /*Navbar height and rest of the content */
    grid-template-columns: 200px 1fr;  /*Sidebar width and main content */
    grid-template-areas:
      "navbar navbar"
      "sidebar main";
    height: 100vh;
    width: 100vw;
    max-width: 100%;
  }
  
  
  .main {
    grid-area: main;
    padding: 20px;
    background-color: #fff;
  }
  


  /* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of everything */
}

/* Spinner animation */
.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ccc;
  border-top: 6px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
 `, "",{"version":3,"sources":["webpack://./src/Components/MainApp/MainApp.css"],"names":[],"mappings":";AACA;IACI,SAAS;IACT,UAAU;IACV,YAAY;EACd;;EAEA;IACE,aAAa;IACb,4BAA4B,GAAG,yCAAyC;IACxE,gCAAgC,GAAG,kCAAkC;IACrE;;oBAEgB;IAChB,aAAa;IACb,YAAY;IACZ,eAAe;EACjB;;;EAGA;IACE,eAAe;IACf,aAAa;IACb,sBAAsB;EACxB;;;;EAIA,YAAY;AACd;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,0BAA0B;EAChE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,qCAAqC;AACtD;;AAEA,sBAAsB;AACtB;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,6BAA6B;EAC7B,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA,0BAA0B;AAC1B;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["\nbody, html, #root {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n  }\n  \n  .container {\n    display: grid;\n    grid-template-rows: 60px 1fr;  /*Navbar height and rest of the content */\n    grid-template-columns: 200px 1fr;  /*Sidebar width and main content */\n    grid-template-areas:\n      \"navbar navbar\"\n      \"sidebar main\";\n    height: 100vh;\n    width: 100vw;\n    max-width: 100%;\n  }\n  \n  \n  .main {\n    grid-area: main;\n    padding: 20px;\n    background-color: #fff;\n  }\n  \n\n\n  /* Overlay */\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Ensure it's on top of everything */\n}\n\n/* Spinner animation */\n.spinner {\n  width: 50px;\n  height: 50px;\n  border: 6px solid #ccc;\n  border-top: 6px solid #007bff;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n/* Keyframes for spinner */\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
