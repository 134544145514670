import React, { useState } from 'react';
import '../MainSection.css'
import axiosInstance from '../../../Axios.js'
import { ImDownload } from "react-icons/im";
import { ImEye } from "react-icons/im";
import image_nano from  '../../../img/image_3.jpg';

import TmatrixContent from '../TmatrixContentBox/TmatrixContentBox.js'


const TmatrixCard = ({ data }) => {


  const [SelectedCard, setSelectedCard] = useState();
  const [SelectedElement, setSelectedElement] = useState([]);

  const [OpenWindows, setOpenWindows] = useState(false);

  
  const colors = [
    "#FF5733", // Vivid Red-Orange
    "#33FF57", // Neon Green
    "#3357FF", // Bright Blue
    "#FF33F6", // Bright Magenta
    "#F3FF33", // Lemon Yellow
    "#33FFF5", // Turquoise
    "#8E44AD", // Medium Purple
    "#3498DB", // Sky Blue
    "#2ECC71", // Shamrock Green
    "#E67E22", // Pumpkin Orange
    "#ECF0F1", // Cloud White
    "#95A5A6", // Cadet Blue
    "#D35400", // Carrot Orange
    "#C0392B", // Pomegranate Red
    "#BDC3C7", // Silver Sand
    "#7F8C8D", // Slate Gray
    "#F1C40F", // Golden Yellow
    "#1ABC9C", // Aqua Marine
    "#2C3E50", // Midnight Blue
    "#34495E", // Wet Asphalt Gray

  ];
  // Number of elements to display per page

  const currentElements = {};
  const elementsPerPage = 8;

  // State to track the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(Object.keys(data).length / elementsPerPage);

  // Get the current elements to display based on the current page
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
 
  const keys = Object.keys(data);
  
  // Slice the keys to get the first five
  const firstFiveKeys = keys.slice(indexOfFirstElement, indexOfLastElement);

  // Create a new object with only the first five key-value pairs
  
  firstFiveKeys.forEach(key => {
    currentElements[key] = data[key];
  });

  

 

  const handleDownload = (index) => {

  

    setSelectedCard(index)
   
   
  
    const form_data = new FormData();
    form_data.append('hdfile', data[index].source_file);
  
    axiosInstance.post('/caosdb_download/', form_data, {
      responseType: 'blob', // Tells Axios to expect a binary response
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data[index].source_file); // Ensure this has a file extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    }).catch((error) => {
      console.error(error);
    });
      
    
  };

  const handlehDisplayCard = (index) =>{

     setSelectedElement(data[index])

    

    

   
    setOpenWindows(true)

  }

  const getKeywords = (currentElement, key) => {
    // Safely access the tmatrix_Keyword property
    const keywordString = currentElement[key]?.tmatrix_Keyword;

    console.log(keywordString)

    if (!keywordString) {
       
        return "No keywords"; // Provide a default fallback
    }

    try {
        // Replace single quotes with double quotes and parse the JSON
        const keywordsArray = JSON.parse(keywordString.replace(/'/g, '"'));

        // Check if the result is an array and then join it, or log error
        if (Array.isArray(keywordsArray)) {
            return keywordsArray.join(', ');
        } else {
            
            return "Invalid keyword format"; // fallback message
        }
    } catch (error) {
        console.error("Parsing error:", error);
        return "Error parsing keywords"; // error handling
    }
};

  return (
    <div>
      
    <div className="Matrix-element">

    



<div className="table-container">


<div className="cards-container">
        {Object.keys(currentElements).map((key) => (
          <div className="card-container">
            <div className="card-header" style={{ /*backgroundColor: colors[Math.floor(Math.random() * colors.length)]*/  backgroundImage: `url(${image_nano}`,  backgroundSize: 'cover' }}></div>
            <div className="card-content">
              <h3>{currentElements[key]['geometry_shape']}  </h3>
              {<p>Material : {currentElements[key]['material_name']}</p>}
              <p>Description : {currentElements[key]['tmatrix_description']}</p>
              <p>Keywords : {getKeywords(currentElements, key)}</p>
              
              <p className='p-style'><ImEye className="Icon-show" onClick={() => {handlehDisplayCard(key)}}/> <ImDownload className="Icon-download" onClick={() => {handleDownload(key)}}/></p>
              
              
              
            </div>
          </div>
        ))}
      </div>




      { OpenWindows ? <TmatrixContent data={SelectedElement} onClose={() => {setOpenWindows(false)}} />: null}
          
      
      </div>

      

    </div>

   
    </div>
  );
};

export default TmatrixCard ;
