// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ApiComponent.css */

/* General container styling */
.api-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  margin: 0; /* Remove outer margin */
  padding: 0; /* Remove padding to avoid spacing on edges */
  overflow: auto; /* Allow scrolling if needed */
}

/* Header styling */
.api-container h1 {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #007bff; /* Primary color accent */
  padding-bottom: 10px;
  width: 100%;
}

/* Swagger UI styling */
.api-container .swagger-ui {
  width: 100%;
  height: calc(100vh - 100px); /* Adjust for header height */
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ApiComponent/ApiComponent.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB,8BAA8B;AAC9B;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,YAAY,EAAE,wBAAwB;EACtC,aAAa,EAAE,yBAAyB;EACxC,SAAS,EAAE,wBAAwB;EACnC,UAAU,EAAE,6CAA6C;EACzD,cAAc,EAAE,8BAA8B;AAChD;;AAEA,mBAAmB;AACnB;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC,EAAE,yBAAyB;EAC3D,oBAAoB;EACpB,WAAW;AACb;;AAEA,uBAAuB;AACvB;EACE,WAAW;EACX,2BAA2B,EAAE,6BAA6B;EAC1D,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["/* ApiComponent.css */\n\n/* General container styling */\n.api-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  width: 100vw; /* Full viewport width */\n  height: 100vh; /* Full viewport height */\n  margin: 0; /* Remove outer margin */\n  padding: 0; /* Remove padding to avoid spacing on edges */\n  overflow: auto; /* Allow scrolling if needed */\n}\n\n/* Header styling */\n.api-container h1 {\n  font-size: 2em;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 20px;\n  text-align: center;\n  border-bottom: 2px solid #007bff; /* Primary color accent */\n  padding-bottom: 10px;\n  width: 100%;\n}\n\n/* Swagger UI styling */\n.api-container .swagger-ui {\n  width: 100%;\n  height: calc(100vh - 100px); /* Adjust for header height */\n  border: 1px solid #e0e0e0;\n  border-radius: 4px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
