// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Sidebar.css */

.SideBar {
  grid-area: sidebar;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  font-family: 'Segoe UI', sans-serif;
}

.separator {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ddd;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}




.btnf {
  padding: 8px 12px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;
  color: #fff;
  width: 100%;
  text-align: center;
}

.include {
  background-color: #5b705b;
}

.exclude {
  background-color: #ba817d;
  margin-top: 10px;
}

.btnf:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}

`, "",{"version":3,"sources":["webpack://./src/Components/SideBarSection/SideBarSection.css"],"names":[],"mappings":";;AAEA,gBAAgB;;AAEhB;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;AACvB;;;;;AAKA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,sDAAsD;EACtD,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,YAAY;AACd","sourcesContent":["\n\n/* Sidebar.css */\n\n.SideBar {\n  grid-area: sidebar;\n  background-color: #f9f9f9;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n  max-width: 250px;\n  font-family: 'Segoe UI', sans-serif;\n}\n\n.separator {\n  margin: 20px 0;\n  border: 0;\n  border-top: 1px solid #ddd;\n}\n\n.title {\n  font-size: 1rem;\n  font-weight: 600;\n  color: #333;\n  margin-bottom: 15px;\n  text-transform: uppercase;\n  letter-spacing: 0.5px;\n}\n\n\n\n\n.btnf {\n  padding: 8px 12px;\n  font-size: 0.85rem;\n  font-weight: 500;\n  cursor: pointer;\n  border: none;\n  border-radius: 5px;\n  transition: background 0.3s ease, box-shadow 0.3s ease;\n  margin-top: 10px;\n  color: #fff;\n  width: 100%;\n  text-align: center;\n}\n\n.include {\n  background-color: #5b705b;\n}\n\n.exclude {\n  background-color: #ba817d;\n  margin-top: 10px;\n}\n\n.btnf:hover {\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  opacity: 0.9;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
