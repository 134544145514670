// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documentation-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
    font-family: "Helvetica", sans-serif;
    line-height: 1.6;
    text-align: left; /* Align text to the left */
}

/* Headers styling */
.documentation-container h1,
.documentation-container h2,
.documentation-container h3 {
    color: #333;
    margin-top: 20px;
    text-align: left; /* Ensure headers are also left-aligned */
}

/* Paragraphs */
.documentation-container p {
    margin-bottom: 1em;
    text-align: left; /* Ensure paragraphs are left-aligned */
}

/* Lists */
.documentation-container ul, .documentation-container ol {
    padding-left: 1.5em;
    text-align: left; /* Ensure lists are also left-aligned */
}

/* Links */
.documentation-container a {
    color: #007bff;
    text-decoration: none;
}

.documentation-container a:hover {
    text-decoration: underline;
}

/* Code blocks */
.documentation-container pre {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
}

.documentation-container code {
    font-family: monospace;
    color: #d63384;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserGuide/UserGuide.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,oCAAoC;IACpC,gBAAgB;IAChB,gBAAgB,EAAE,2BAA2B;AACjD;;AAEA,oBAAoB;AACpB;;;IAGI,WAAW;IACX,gBAAgB;IAChB,gBAAgB,EAAE,yCAAyC;AAC/D;;AAEA,eAAe;AACf;IACI,kBAAkB;IAClB,gBAAgB,EAAE,uCAAuC;AAC7D;;AAEA,UAAU;AACV;IACI,mBAAmB;IACnB,gBAAgB,EAAE,uCAAuC;AAC7D;;AAEA,UAAU;AACV;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,gBAAgB;AAChB;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".documentation-container {\n    max-width: 100%;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f8f9fa;\n    color: #333;\n    font-family: \"Helvetica\", sans-serif;\n    line-height: 1.6;\n    text-align: left; /* Align text to the left */\n}\n\n/* Headers styling */\n.documentation-container h1,\n.documentation-container h2,\n.documentation-container h3 {\n    color: #333;\n    margin-top: 20px;\n    text-align: left; /* Ensure headers are also left-aligned */\n}\n\n/* Paragraphs */\n.documentation-container p {\n    margin-bottom: 1em;\n    text-align: left; /* Ensure paragraphs are left-aligned */\n}\n\n/* Lists */\n.documentation-container ul, .documentation-container ol {\n    padding-left: 1.5em;\n    text-align: left; /* Ensure lists are also left-aligned */\n}\n\n/* Links */\n.documentation-container a {\n    color: #007bff;\n    text-decoration: none;\n}\n\n.documentation-container a:hover {\n    text-decoration: underline;\n}\n\n/* Code blocks */\n.documentation-container pre {\n    background-color: #e9ecef;\n    padding: 10px;\n    border-radius: 4px;\n    overflow-x: auto;\n}\n\n.documentation-container code {\n    font-family: monospace;\n    color: #d63384;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
