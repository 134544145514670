// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  grid-area: navbar;
  /*background-color: #002155;*/
  background: linear-gradient(#d5b7a3, #002155);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 50px; /* Adjust the height as needed */
  width: auto;
}

.navbar-logo .logo-image {
  margin-right: 10px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar-item a {
  color: white;
  text-decoration: none;
}

.navbar-item a:hover {
  text-decoration: underline;
}

.navbar-item h5 {
  margin: 0;
  padding-right: 10px;
}

.icon-logout {
  cursor: pointer;
  font-size: 1.2rem;
  color: white;
}

.sidebar {
  grid-area: sidebar;
  background-color: #f4f4f4;
  padding: 20px;
}

.main {
  grid-area: main;
  padding: 20px;
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/Components/NavBarSection/NavBarSection.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,6CAA6C;EAC7C,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY,EAAE,gCAAgC;EAC9C,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".navbar {\n  grid-area: navbar;\n  /*background-color: #002155;*/\n  background: linear-gradient(#d5b7a3, #002155);\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n}\n\n.navbar-logo {\n  display: flex;\n  align-items: center;\n}\n\n.logo-image {\n  height: 50px; /* Adjust the height as needed */\n  width: auto;\n}\n\n.navbar-logo .logo-image {\n  margin-right: 10px;\n}\n\n.navbar-menu {\n  list-style: none;\n  display: flex;\n  margin: 0;\n  padding: 0;\n}\n\n.navbar-item {\n  margin: 0 15px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.navbar-item a {\n  color: white;\n  text-decoration: none;\n}\n\n.navbar-item a:hover {\n  text-decoration: underline;\n}\n\n.navbar-item h5 {\n  margin: 0;\n  padding-right: 10px;\n}\n\n.icon-logout {\n  cursor: pointer;\n  font-size: 1.2rem;\n  color: white;\n}\n\n.sidebar {\n  grid-area: sidebar;\n  background-color: #f4f4f4;\n  padding: 20px;\n}\n\n.main {\n  grid-area: main;\n  padding: 20px;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
