// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GeometryShape.css */

/* Title Styling */
.Title-Geometry {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px; /* Reduced margin for tighter layout */
  text-transform: uppercase;
}

/* List Styling */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.checkbox-item {
  display: flex;
  align-items: center;
  padding: 4px 0; /* Reduced padding */
  margin-bottom: 4px; /* Reduced margin for closer spacing */
}

/* Checkbox Styling */
.checkbox-item input[type="checkbox"] {
  accent-color: #7490c7;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
}

.checkbox-item label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SideBarSection/GeometrySection/GeometryShape.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB,kBAAkB;AAClB;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,kBAAkB,EAAE,sCAAsC;EAC1D,yBAAyB;AAC3B;;AAEA,iBAAiB;AACjB;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc,EAAE,oBAAoB;EACpC,kBAAkB,EAAE,sCAAsC;AAC5D;;AAEA,qBAAqB;AACrB;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb","sourcesContent":["/* GeometryShape.css */\n\n/* Title Styling */\n.Title-Geometry {\n  font-size: 1rem;\n  font-weight: 600;\n  color: #333;\n  margin-bottom: 8px; /* Reduced margin for tighter layout */\n  text-transform: uppercase;\n}\n\n/* List Styling */\nul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.checkbox-item {\n  display: flex;\n  align-items: center;\n  padding: 4px 0; /* Reduced padding */\n  margin-bottom: 4px; /* Reduced margin for closer spacing */\n}\n\n/* Checkbox Styling */\n.checkbox-item input[type=\"checkbox\"] {\n  accent-color: #7490c7;\n  width: 18px;\n  height: 18px;\n  margin-right: 8px;\n  cursor: pointer;\n}\n\n.checkbox-item label {\n  font-size: 0.9rem;\n  font-weight: 500;\n  color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
