import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LogIn from "./Components/LogIn/LogIn.js";
import MainApp from "./Components/MainApp/MainApp.js";
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage.js";
import ApiComponent from './Components/ApiComponent/ApiComponent.js';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy.js'
import UserGuide from './Components/UserGuide/UserGuide.js'
import './App.css';

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    
    const urlParams = new URLSearchParams(window.location.search);
    const isLoginParam = urlParams.get('isLogin');
    const userIdParam = urlParams.get('userId');
    setIsLogin(!!isLoginParam); 
    setUserId(userIdParam)
    
  }, []);


  const PrivateRoute = ({ children }) => {
    
    localStorage.setItem('user_id', userId);
    return isLogin ? children : <Navigate to='/' push={true} />;
  };


  

  return (
    <div className="App">
      
        <Routes>
          <Route path='/' element={isLogin ? <Navigate push={true} to='/Home' /> : <LogIn />} />
          <Route path='/Home' element={<PrivateRoute><MainApp /></PrivateRoute>} />
          <Route path='/ApiComponent' element={<PrivateRoute><ApiComponent /></PrivateRoute>} />
          <Route path='/PrivacyPolicy' element={<PrivateRoute><PrivacyPolicy /></PrivateRoute>} />
          <Route path='/UserGuide' element={<PrivateRoute><UserGuide /></PrivateRoute>} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
   
    </div>
  );
}

export default App;
