
import React, { useEffect, useState } from 'react';

import './MetricElement.css'

import DoubleSlider from "../DoubleSlider/DoubleSlider.js";

export default function MetricsElemts ({handleMetrics, setShowAction}) {



  const [selectedElement, setSelectedElement] = useState('');
  const [inputValue, setInputValue] = useState([]);
  


  const elements = ['Frequency', 'Vacuum Wavelength'];

  const handleSelectChange = (event) => {

   
    if (event.target.value === 'clear') {
      setSelectedElement('');
      setInputValue('');  // Reset input value when selection changes
      setShowAction(false)
    } else {
      setSelectedElement(event.target.value);
      setShowAction(true)
    }
    
  };


  const handleRangeChange = (range) => {
    
    setInputValue(range);
    
    
  };

    

  useEffect(() => {

 
    let elemetselected = {}


    if (inputValue && selectedElement) {

      elemetselected[selectedElement] = inputValue
     
      handleMetrics(elemetselected)
  

    }else{

      handleMetrics(elemetselected)
    }
  }, [ inputValue, selectedElement ]);


    return (


      <div>

<h2 className='Title-Metrics'>Metrics</h2>

<hr className="separator" />

      <select id="element-select" value={selectedElement} onChange={handleSelectChange} className="input-menu" >
        <option value="" disabled>Select Metrics</option>
        {elements && <option value="clear">Clear selection</option>}
        {elements.map((element) => (
          <option key={element} value={element}>
            {element}
          </option>
        ))}
      </select>

    

      { selectedElement && (

            <div>
             
            <DoubleSlider
              min={0}
              max={0}
              onChange={handleRangeChange}
            />
          </div>

)
      }


  


    </div>

 

 


    
      
    

      




    )

    


};