import React from "react";
import './NavBarSection.css'
import {Link } from 'react-router-dom'
import { ImExit } from "react-icons/im";

import logo from "../../img/Logo_KIT.svg.png" 





export default function NavBarSection (){


    let domain = window.location.hostname;
    let api = '/api'

    let url_prod = 'https://' + domain + api
  
    let url_dev = 'http://' + domain +':5000' 


      const handleLogOut = (event) => {


            localStorage.clear();
            window.location.href = "/";
      
           }


return(


<div className="navbar">
      <div className="navbar-logo"> <img src={logo} alt="MyApp Logo" className="logo-image" /></div>
      <ul className="navbar-menu">
      <li className="navbar-item">
          <Link to="/ApiComponent" >APIs</Link>
        </li>
        <li className="navbar-item">
          <a 
            href="https://www.bildung-forschung.digital/digitalezukunft/de/wissen/Datenkompetenzen/datenkompetenzen_wissenschaftlichen_nachwuchs/Projekte/daphona.html?nn=729750" 
            target="_blank" 
            rel="noopener noreferrer">
            About
          </a>
        </li>
        <li className="navbar-item">
          <Link to="/PrivacyPolicy">Privacy policy</Link>
        </li>
        <li className="navbar-item">
         {/* <Link to="/UserGuide" >User Guide</Link>*/}
         <Link to="https://tmatrix.scc.kit.edu/documentationmarkdown/documentationpdf/User_Guide_Tmatrix_Web_Application.pdf" target="_blank">
         User Guide
      </Link>
        </li>
        <li className="navbar-item">
          <h5>{'Hello ' + localStorage.getItem('user_id') + ' !'}</h5>
          <ImExit className="icon-logout" onClick={handleLogOut} />
        </li>
      </ul>
    </div>




 
      
  

      


)

}