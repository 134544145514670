import React, { useState } from 'react';
import { IoMdAdd } from "react-icons/io";
import { RiCoinsLine, RiDeleteBin6Line } from "react-icons/ri";
import DoubleSlider from "../../SideBarSection/DoubleSlider/DoubleSlider.js";
import './FilterBox.css'

const FilterBox = ({ onClose, handleSecondFilter, ListMaterial , shapeParams}) => {
  const [fields, setFields] = useState([{ selectedElement: '', inputValue: '', inputParam : {} }]);
  const elements = ['Geometry', 'Materials', 'Frequency', 'Vacuum Wavelength'];
  const [spectrunUnit, setSpectrumUnit] = useState({ 'Frequency': 'THz', 'Vacuum Wavelength': 'nm' });

  

 
  const handleSelectChange = (index, event) => {
    const newFields = [...fields];
  
    newFields[index].selectedElement = event.target.value;
    
    newFields[index].selectedElement = event.target.value;
    newFields[index].inputValue = ""; 
    setFields(newFields);
  };

  
  const handleInputChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].inputValue = event.target.value;
    setFields(newFields);
  };

  const handleAddField = () => {
    setFields([...fields, { selectedElement: '', inputValue: '' }]);
  };

  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleApplyFilter = () => {
    handleSecondFilter(fields);
  };

  const handleRangeChange = (index, range) => {
    const newFields = [...fields];
    newFields[index].inputValue = range;
    setFields(newFields);
  };

  const handleParamChange = (event, param, index) => {

    const newFields = [...fields];
    console.log(event.target.value)
    console.log('param : ', param)


    if (!newFields[index]) {
      console.log('here please 1')
      newFields[index] = {}; 
     }else if (!newFields[index].inputValue) {
      console.log('here please 2')
        newFields[index].inputParam = {};  
    }else{
   

      console.log('here please : ', newFields[index])
      newFields[index].inputParam = {}; 
      
      newFields[index].inputParam[param] =  event.target.value
    } 
    console.log(newFields)
    setFields(newFields);
};

  return (
    <div className="tmatrix-modal-backdrop-filter" onClick={onClose}>
      <div className="tmatrix-modal-content-filter" onClick={(e) => e.stopPropagation()}>
        <div className="filter-buttons">
          <button onClick={onClose} className="button-close-filter">Close</button>
          <button onClick={handleApplyFilter} className="button-close-filter">Apply Filter</button>
          <button onClick={handleAddField} className="button-add"><IoMdAdd /></button>
        </div>
        <div className="filter-fields">
          
          {fields.map((field, index) => (
            
            <div key={index} className="list-select-filter">
              <button onClick={() => handleRemoveField(index)} className="button-close-filter">
                <RiDeleteBin6Line />
              </button>
              <select id={`element-select-${index}`} value={field.selectedElement} onChange={(e) => handleSelectChange(index, e)} className="input-menu-filter">
                <option value="" disabled>Select...</option>
                {elements.map((element) => (
                  <option key={element} value={element}>{element}</option>
                ))}
              </select>
              {['Frequency', 'Vacuum Wavelength'].includes(field.selectedElement) && (
                <div className="range-container">
                  <label htmlFor={`element-value-${index}`} className="label">
                    {`Select ${field.selectedElement} Range in ${spectrunUnit[field.selectedElement]}:`}
                  </label>
                  <DoubleSlider min={0} max={0} onChange={(range) => handleRangeChange(index, range)} />
                </div>
              )}
                {field.selectedElement === "Geometry" && (
                <div className="nested-select-container">
                <select
                  id={`geometry-select-${index}`}
                  value={field.inputValue}
                  onChange={(e) => handleInputChange(index, e)}
                  className="input-menu-filter"
                >
                  <option value="" disabled>Select...</option>
                  {Object.keys(shapeParams) && <option value="clear">Clear selection</option>}
                  {Object.keys(shapeParams).map((element) => (
                    <option key={element} value={element}>{element}</option>
                  ))}
                </select>
                </div>
                )}

                {field.selectedElement === "Geometry" && field.inputValue && shapeParams[field.inputValue] && (
                <div className="modal-content-filter">
                <h3>{field.inputValue} Parameters</h3>
                <ul>
                  {shapeParams[field.inputValue].map((param, paramIndex) => (
                    <li key={paramIndex}>
                      <label>{param}: </label>
                      <input
                        type="text"
                        name={param}
                        value={field.inputParam?.[param] || ""}
                        onChange={(e) => handleParamChange(e, param, index)}
                      />
                    </li>
                  ))}
                </ul>
                </div>
                )}


              {field.selectedElement === 'Materials' && (
                <div className="nested-select-container">
                  <select id={`material-select-${index}`} value={field.inputValue} onChange={(e) => handleInputChange(index, e)} className="input-menu-filter">
                    <option value="" disabled>Select...</option>
                    {ListMaterial.map((element) => (
                      <option key={element} value={element} >{element}</option>
                    ))}
                  </select>
                </div>
              )}
              
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default FilterBox;
